import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import '@radix-ui/themes/styles.css';
import { Theme } from '@radix-ui/themes';

export function Index() {
  return (
    <html>
      <body>
        <Theme appearance="dark" accentColor="amber" scaling="100%">
          <App />
        </Theme>
      </body>
    </html>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <Index />
  </React.StrictMode>,
  document.getElementById('root')
);